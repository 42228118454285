<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headers"
            item-key="benAgectaId"
            :items="gruposFamiliares"
            class="elevation-1"
            no-data-text="El agente de cuenta no está relacionado a un grupo familiar"
            :loading="loading"
            loading-text="Cargando datos..."
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="10" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      title
                    }}</v-card-title>
                  </v-col>
                  <!-- <v-col cols="12" md="3">
                  <v-card-subtitle> {{ agectaNom }}</v-card-subtitle>
                </v-col> -->
                  <v-col cols="12" md="2" class="pt-4 text-right">
                    <v-btn
                      color="primary"
                      @click="openModalNewRelacionGrupoFamiliar()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEditRelacionGrupoFamiliar(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar relación con grupo familiar </span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteFormaPago(item.benAgectaId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar relación con grupo familiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left: 100px">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left: 100px">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left: 100px"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
          <!-- Modal edit relación con grupo familiar -->
          <v-dialog
            v-if="modalEditRelacionGrupoFamiliar"
            v-model="modalEditRelacionGrupoFamiliar"
            max-width="60rem"
            @keydown.esc="closeModalCargaRelacionGrupoFamiliar()"
            persistent
          >
            <EditRelacionGrupoFamiliar
              :benAgectaId="benAgectaId"
              :agectaNom="agectaNom.toUpperCase()"
              :agectaId="agectaId"
              :benGrId="benGrId"
              :periodoDesdeProp="periodoDesde"
              :periodoHastaProp="periodoHasta"
              :tipoRelacionId="tipoRelacionId"
              :titularDni="titularDni"
              :titularNom="titularNom"
              @closeModalCargaRelacionGrupoFamiliar="
                closeModalCargaRelacionGrupoFamiliar
              "
              @relacionCargada="actualizarDataTable = $event"
              @setGruposFamiliares="setGruposFamiliares"
            ></EditRelacionGrupoFamiliar>
          </v-dialog>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal">
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- <v-container class="pt-0 mt-0">
      <v-layout>
        <v-flex>
         
        </v-flex>
      </v-layout>
    </v-container> -->

    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import EditRelacionGrupoFamiliar from "./EditRelacionGrupoFamiliar.vue";

export default {
  components: { DeleteDialog, EditRelacionGrupoFamiliar },
  name: "RelacionesGrupoFamiliar",
  props: {
    agectaId: { type: Number, required: true, default: null },
    agectaNom: { type: String, required: true, default: null }
  },
  data() {
    return {
      title: `Grupos familiares relacionados al agente: ${this.agectaNom.toUpperCase()}`,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      rules: rules,
      gruposFamiliares: [],
      titleDelete: "¿Eliminar registro?",
      showDeleteModal: false,
      actualizarDataTable: false,
      idToDelete: null,
      modalEditRelacionGrupoFamiliar: false,
      idRelacionAgectaGrupo: null,
      loading: false,
      benAgectaId: null,
      periodoDesde: null,
      periodoHasta: null,
      titularDni: null,
      titularNom: null,
      tipoRelacionId: null,
      headers: [
        {
          text: "Grupo",
          align: "start",
          sortable: false,
          value: "benGrId"
        },
        {
          text: "Titular",
          sortable: false,
          value: "titularDatos.nombre"
        },
        {
          text: "Período desde",
          sortable: false,
          value: "periodoDesde"
        },
        {
          text: "Período hasta",
          sortable: false,
          value: "periodoHasta"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center"
        },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      expanded: []
    };
  },
  created() {
    this.setGruposFamiliares();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getRelacionesGrupoFamiliar: "agenteCuenta/getRelacionesGrupoFamiliar",
      deleteRelacionGrupoFamiliar: "agenteCuenta/deleteRelacionGrupoFamiliar"
    }),
    deleteFormaPago(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteRelacionGrupoFamiliar(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setGruposFamiliares();
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async setGruposFamiliares() {
      this.loading = true;
      const data = await this.getRelacionesGrupoFamiliar(this.agectaId);
      this.loading = false;
      this.gruposFamiliares = data;
    },
    closeModal() {
      this.$emit("toggleModalRelacionGrupoFamiliar");
    },
    closeModalCargaRelacionGrupoFamiliar() {
      this.actualizarDataTable ? this.setGruposFamiliares() : [];
      this.modalEditRelacionGrupoFamiliar = false;
      this.actualizarDataTable = false;
      this.benAgectaId = null;
      this.benGrId = null;
      this.periodoDesde = null;
      this.periodoHasta = null;
      this.tipoRelacionId = null;
      this.titularDni = null;
      this.titularNom = null;
    },
    openModalEditRelacionGrupoFamiliar(titular) {
      this.benAgectaId = titular.benAgectaId;
      this.benGrId = titular.benGrId;
      this.modalEditRelacionGrupoFamiliar = !this
        .modalEditRelacionGrupoFamiliar;
      this.periodoDesde = titular.periodoDesde;
      this.periodoHasta = titular.periodoHasta;
      this.tipoRelacionId = titular.tipoBenAgeId;
      this.titularDni = titular.titularDatos
        ? titular.titularDatos.documento
        : null;
      this.titularNom = titular.titularDatos
        ? titular.titularDatos.nombre
        : null;
    },

    openModalNewRelacionGrupoFamiliar() {
      this.benAgectaId = null;
      this.benGrId = null;
      this.periodoDesde = null;
      this.periodoHasta = null;
      this.tipoRelacionId = null;
      this.titularDni = null;
      this.titularNom = null;
      this.modalEditRelacionGrupoFamiliar = !this
        .modalEditRelacionGrupoFamiliar;
    }
    // !this.modalEditRelacionGrupoFamiliar ? this.setGruposFamiliares() : [];
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
