<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="py-0">
          <v-card-title class="py-0">
            <span class="primary--text">{{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-form
              v-model="isFormValid"
              ref="form"
              form="form"
              id="form"
              @submit.prevent="saveEditComprobante()"
            >
              <v-row>
                <!-- TIPO COMPROBANTE -->
                <v-col cols="12" md="3" class="pb-0">
                  <v-autocomplete
                    v-model="tipoComprobante"
                    label="Tipo de comprobante"
                    dense
                    outlined
                    disabled
                  >
                  </v-autocomplete>
                  <!-- NUMERO DE COMPROBANTE -->
                  <v-text-field
                    v-model="nroComprobante"
                    label="N° de comprobante"
                    dense
                    outlined
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <!-- AGENTE DE CUENTA -->
                <v-col cols="12" md="3" class="pb-0">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    label="Agente de cuenta"
                    v-model="agectaNom"
                  >
                  </v-text-field>
                  <!-- CODIGO DEL AGENTE -->
                  <v-text-field
                    dense
                    outlined
                    disabled
                    label="Código del agente"
                    v-model="agectaId"
                  >
                  </v-text-field>
                </v-col>
                <!-- LOCALIDAD -->
                <v-col cols="12" md="3" class="pb-0">
                  <v-autocomplete
                    dense
                    outlined
                    label="Localidad"
                    v-model="localidadSelected"
                  >
                  </v-autocomplete>
                  <!-- CONDICION DE IVA -->
                  <v-autocomplete
                    dense
                    outlined
                    label="Condición de IVA"
                    v-model="condicionIvaSelected"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="pb-0">
                  <!-- CUIT -->
                  <v-text-field dense outlined label="CUIT" v-model="cuit">
                  </v-text-field>
                  <!-- INGRESOS BRUTOS -->
                  <v-text-field
                    v-model="ingBrutos"
                    dense
                    outlined
                    label="Ingresos brutos"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- FORM COMPROBANTE -->
              <v-card class="pt-0" elevation="5">
                  <v-row class="pt-0">
                    <v-col cols="12" md="12" class="text--right">
                      <v-card-title class="py-0"
                        ><span class="primary--text "
                          >Comprobantes</span
                        ></v-card-title
                      >
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-row class="py-0">
                        <v-col cols="12" md="6" class="py-0 pl-6">
                          <v-autocomplete
                            dense
                            outlined
                            label="Estado comprobante"
                            v-model="estadoComprobante"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <v-autocomplete
                            dense
                            outlined
                            label="Caja/Usuario"
                            v-model="cajaUsuario"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-row class="pt-0">
                        <v-col cols="12" md="6" class="py-0 pl-6">
                          <v-text-field
                            v-model="periodo"
                            dense
                            outlined
                            label="Período"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <v-autocomplete
                            dense
                            outlined
                            v-model="convenio"
                            label="Convenio"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="py-0">
                        <v-col cols="12" md="6" class="py-0 pl-6">
                          <v-menu
                    ref="menu"
                    v-model="menuDesde"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="pt-2"
                        v-model="fechaDesdeTextField"
                        label="Comprobantes desde"
                        :append-icon="calendarIcon"
                        v-mask="'##/##/####'"
                        hint="formato DD/MM/AAAA"
                        @keydown.tab="menuDesde = false"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        @blur="
                          [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                        "
                        @change="
                          [
                            (fechaDesde = parseDateToIso(fechaDesdeTextField)),
                            changeSaldoDesdeLabel(fechaDesdeTextField)
                          ]
                        "
                        clearable
                        dense
                        outlined
                        autocomplete="off"
                        :rules="rules.required.concat(rules.validDate)"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="fechaDesde"
                      no-title
                      scrollable
                      @change="
                        [
                          (fechaDesdeTextField = formatDate(fechaDesde)),
                          changeSaldoDesdeLabel(fechaDesdeTextField)
                        ]
                      "
                      @input="menuDesde = false"
                    ></v-date-picker>
                  </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <v-text-field
                            v-model="fechaVencimiento"
                            dense
                            outlined
                            label="Fecha Vencimiento"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- DATA TABLE COMPROBANTE -->
                    <v-col cols="12" md="6" class="py-0">
                      <v-data-table
                        :headers="headersComprobantes"
                        dense
                        item-key=""
                        height="30vh"
                        fixed-header
                        :items="comprobantes"
                        :loading="loadingComprobantes"
                        hide-items-per-page
                        hide-default-footer
                        loading-text="Cargando datos"
                        class="elevation-3"
                      >
                        <template v-slot:footer>
                          <v-row>
                            <v-col cols="6" md="8" class="pb-0 pt-5 pl-5"
                              ><v-text-field
                                dense
                                label="Observaciones"
                                v-model="observaciones"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="4" class="pb-0 pt-5 pr-5">
                              <v-text-field
                                dense
                                disabled
                                label="Total"
                                v-model="sumaTotales"
                              ></v-text-field
                            ></v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
              </v-card>
              <!-- FORMAS DE PAGO DATA TABLE -->
              <v-card>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-title class="py-0">
                      <span class="primary--text py-0">Formas de pago</span>
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" md="12" class="px-6 pb-0 pt-0">
                    <v-data-table
                      :headers="headersFormasDePago"
                      dense
                      item-key=""
                      height="30vh"
                      fixed-header
                      :items="formasDePago"
                      :loading="loadingFormasDePago"
                      hide-default-footer
                      loading-text="Cargando datos"
                      class="elevation-3"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <!-- CERRAR Y GUARDAR -->
    <v-col cols="12" class="text-right">
      <v-btn outlined class="mr-4" @click="closeModal()">
        Cerrar
      </v-btn>
      <v-btn class="primary ml-2">
        Aplicar
      </v-btn>
    </v-col>
  </v-card>
</template>

<script>
export default {
  props: {
    agectaId: { type: Number, required: true, default: null },
    agectaNom: { type: String, required: true, default: "" }
  },
  data() {
    return {
      title: "Consulta de comprobante del agente",
      isFormValid: false,
      tipoComprobante: null,
      nroComprobante: null,
      localidadSelected: null,
      localidades: [],
      condicionIvaSelected: null,
      condicionesIva: [],
      ingBrutos: null,
      cuit: null,
      cajaUsuario: null,
      convenio: null,
      fecha: null,
      fechaVencimiento: null,
      comprobantes: [],
      formasDePago: [],
      estadoComprobante: null,
      periodo: null,
      loadingComprobantes: false,
      loadingFormasDePago: false,
      observaciones: null,
      sumaTotales: "$405035",
      headersComprobantes: [
        {
          text: "Concepto",
          align: "start",
          sortable: false,
          value: "concepto"
        },
        {
          text: "Período",
          sortable: false,
          value: "periodo"
        },
        {
          text: "Neto",
          sortable: false,
          value: "neto"
        },
        {
          text: "IVA",
          sortable: false,
          value: "iva"
        },
        {
          text: "Total",
          sortable: false,
          value: "total"
        }
      ],
      headersFormasDePago: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: false,
          value: "fPago"
        },
        {
          text: "Entidad",
          sortable: false,
          value: "entidad"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe"
        },
        {
          text: "CBU",
          sortable: false,
          value: "cbu"
        },
        {
          text: "Cheque/Cuenta",
          sortable: false,
          value: "chequeCuenta"
        },
        {
          text: "N° auto.",
          sortable: false,
          value: "numeroAuto"
        },
        {
          text: "Banco",
          sortable: false,
          value: "banco"
        },
        {
          text: "Fecha emisión",
          sortable: false,
          value: "fechaEmision"
        },
        {
          text: "Fecha cobro",
          sortable: false,
          value: "fechaCobro"
        }
      ]
    };
  },
  created() {
    this.comprobantes = [
      {
        concepto: "Adicional",
        periodo: "202012",
        neto: "$405039",
        iva: "0.00",
        total: "$405039"
      },
      {
        concepto: "Adicional",
        periodo: "202012",
        neto: "$405039",
        iva: "0.00",
        total: "$405039"
      },
      {
        concepto: "Adicional",
        periodo: "202012",
        neto: "$405039",
        iva: "0.00",
        total: "$405039"
      },
      {
        concepto: "Adicional",
        periodo: "202012",
        neto: "$405039",
        iva: "0.00",
        total: "$405039"
      },
      {
        concepto: "Adicional",
        periodo: "202012",
        neto: "$405039",
        iva: "0.00",
        total: "$405039"
      },
      {
        concepto: "Adicional",
        periodo: "202012",
        neto: "$405039",
        iva: "0.00",
        total: "$405039"
      },
      {
        concepto: "Adicional",
        periodo: "202012",
        neto: "$405039",
        iva: "0.00",
        total: "$405039"
      }
    ];
    this.formasDePago = [
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      },
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      },
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      },
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      },
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      },
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      },
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      },
      {
        fPago: "Contado",
        entidad: "Banco Nacion",
        importe: "$250000",
        cbu: "012345678912",
        chequeCuenta: "Cheque",
        numeroAuto: "405033",
        banco: "Galicia",
        fechaEmision: "20/12/2020",
        fechaCobro: "16/12/2022"
      }
    ];
  },
  methods: {
    closeModal() {
      this.$emit("toggleModalEditCuentaCorriente");
    }
  }
};
</script>

<style scoped></style>
