var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"benAgectaId","items":_vm.gruposFamiliares,"no-data-text":"El agente de cuenta no está relacionado a un grupo familiar","loading":_vm.loading,"loading-text":"Cargando datos...","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"10","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title))])],1),_c('v-col',{staticClass:"pt-4 text-right",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModalNewRelacionGrupoFamiliar()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalEditRelacionGrupoFamiliar(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar relación con grupo familiar ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteFormaPago(item.benAgectaId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar relación con grupo familiar")])])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fechaAlta)+" "),_c('strong',{staticStyle:{"padding-left":"100px"}},[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuAlta)+" "),_c('strong',{staticStyle:{"padding-left":"100px"}},[_vm._v("Fecha modificación:")]),_vm._v(" "+_vm._s(item.fechaModi)+" "),_c('strong',{staticStyle:{"padding-left":"100px"}},[_vm._v("Usuario modificación:")]),_vm._v(" "+_vm._s(item.usuModi)+" ")])]}}],null,true)}),(_vm.modalEditRelacionGrupoFamiliar)?_c('v-dialog',{attrs:{"max-width":"60rem","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalCargaRelacionGrupoFamiliar()}},model:{value:(_vm.modalEditRelacionGrupoFamiliar),callback:function ($$v) {_vm.modalEditRelacionGrupoFamiliar=$$v},expression:"modalEditRelacionGrupoFamiliar"}},[_c('EditRelacionGrupoFamiliar',{attrs:{"benAgectaId":_vm.benAgectaId,"agectaNom":_vm.agectaNom.toUpperCase(),"agectaId":_vm.agectaId,"benGrId":_vm.benGrId,"periodoDesdeProp":_vm.periodoDesde,"periodoHastaProp":_vm.periodoHasta,"tipoRelacionId":_vm.tipoRelacionId,"titularDni":_vm.titularDni,"titularNom":_vm.titularNom},on:{"closeModalCargaRelacionGrupoFamiliar":_vm.closeModalCargaRelacionGrupoFamiliar,"relacionCargada":function($event){_vm.actualizarDataTable = $event},"setGruposFamiliares":_vm.setGruposFamiliares}})],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","align":"end"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }