<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-row>
            <v-col cols="12" md="10" align="start" class="pt-0">
              <v-card-title class="pl-1 primary--text">{{
                title
              }}</v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-model="percepcionSelected"
                :items="percepciones"
                label="Percepción"
                item-text="value"
                item-value="id"
                outlined
                @change="setAlicuotasPercibidas()"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-select
                v-if="percepcionSelected == 'IB'"
                v-model="jurisdiccionSelected"
                :items="jurisdicciones"
                label="Jurisdicción"
                item-text="value"
                item-value="id"
                outlined
                @change="setAlicuotasPercibidas()"
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            item-key="ageCtaAliPercId"
            hide-default-footer
            :items="alicuotasPercibidas"
            class="elevation-1"
            no-data-text="No existen alicuotas percibidas a la fecha..."
            :loading="loading"
            loading-text="Cargando datos..."
          >
          </v-data-table>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal">
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AlicuotasPercibidasAgenteCuenta",
  props: {
    agectaId: { type: Number, required: true, default: null },
    agectaNom: { type: String, required: true, default: null }
  },
  data() {
    return {
      title: `Alícuotas de percepción del agente de cuenta: ${this.agectaNom.toUpperCase()}`,
      alicuotasPercibidas: [],
      loading: false,
      jurisdiccionSelected: null,
      jurisdicciones: [],
      percepcionSelected: null,
      percepciones: [
        {
          id: "IB",
          value: "Ingresos brutos"
        },
        { id: "IVA", value: "IVA" },
        { id: "M", value: "Municipal" }
      ],
      headers: [
        {
          text: "Fecha desde",
          align: "start",
          sortable: false,
          value: "fechaDesde"
        },
        {
          text: "Fecha hasta",
          sortable: false,
          value: "fechaHasta"
        },
        {
          text: "Alícuota",
          sortable: false,
          value: "alicuotaPercepcion"
        },
        {
          text: "Grupo percepción",
          sortable: false,
          value: "grupoPercepcion"
        },
        {
          text: "N° de comprobante",
          sortable: false,
          value: "nroComprobante"
        }
      ]
    };
  },
  created() {
    this.setJurisdicciones();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getAlicuotasPercibidasAgenteCuenta:
        "agenteCuenta/getAlicuotasPercibidasAgenteCuenta",
      getJurisdiccionesIIBB: "agenteCuenta/getJurisdiccionesIIBB"
    }),
    async setJurisdicciones() {
      const jurisdicciones = await this.getJurisdiccionesIIBB();
      this.jurisdicciones = jurisdicciones;
    },
    async setAlicuotasPercibidas() {
      if (
        this.percepcionSelected !== "IB"
      ) {
        this.loading = true;
        const data = await this.getAlicuotasPercibidasAgenteCuenta({
          agectaId: this.agectaId,
          percepcion: this.percepcionSelected,
          juribId: 0
        });
        this.loading = false;
        this.alicuotasPercibidas = data;
      } else if (
        this.percepcionSelected === "IB" &&
        this.jurisdiccionSelected != null
      ) {
        this.loading = true;
        const data = await this.getAlicuotasPercibidasAgenteCuenta({
          agectaId: this.agectaId,
          percepcion: this.percepcionSelected,
          juribId: this.jurisdiccionSelected
        });
        this.loading = false;
        this.alicuotasPercibidas = data;
      }
    },
    closeModal() {
      this.$emit("toggleModalAlicuotaPercibidaAgente");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
