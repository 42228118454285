<template>
  <v-flex>
    <v-card flat>
      <v-card-title class="primary--text">
        {{ title }}
      </v-card-title>
      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <!-- Agente de cuenta -->
          <v-col cols="12" md="12" class="py-0">
            <v-text-field
              v-model="agectaNom"
              label="Agente de cuenta"
              type="text"
              outlined
              disabled
              dense
              readonly
            >
            </v-text-field>
          </v-col>
          <v-form
            v-model="searchValid"
            ref="grupo-familiar-filter"
            id="grupo-familiar-filter"
            @submit.prevent="filterAfiliados()"
          >
            <v-card class="py-0" flat>
              <v-row class="py-0">
                <v-col cols="12" md="4" sm="10" class="pb-3">
                  <v-card-title class="primary--text py-0"
                    >Búsqueda de afiliado</v-card-title
                  >
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  sm="10"
                  align-self="center"
                  class="py-0 text-center "
                >
                  <FiltersSelected
                    :filters="filtersApplyed"
                    v-if="!busquedaAfiliado"
                  />
                </v-col>
                <v-col cols="12" md="3" sm="12" class="text-right pb-0  pr-8">
                  <v-btn
                    v-show="grupoSelected"
                    size="20"
                    @click="toggleBusquedaAfiliado()"
                    class="p-1"
                    dense
                    outlined
                    small
                    ><v-icon>{{ busquedaIcon }}</v-icon>
                    {{ toggleBusquedaTooltip }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row v-show="busquedaAfiliado">
                  <v-col cols="12" md="10" sm="12" class="py-0 pl-8">
                    <v-row>
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchDocumento"
                          label="N° de documento"
                          dense
                          clearable
                          v-mask="'########'"
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchApellido"
                          label="Apellido/s"
                          dense
                          :rules="[rules.maxLength(afiliadoSearchApellido, 50)]"
                          clearable
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchNombre"
                          label="Nombre/s"
                          dense
                          :rules="[rules.maxLength(afiliadoSearchNombre, 50)]"
                          clearable
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="2" class="py-1 text-left">
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      type="submit"
                      form="grupo-familiar-filter"
                    >
                      Buscar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="py-0 text-center">
                    <v-card class="pt-0">
                      <v-data-table
                        class="pt-0"
                        :headers="headers"
                        v-if="filterApplied"
                        :items="gruposFamiliaresResult"
                        :loading="loading"
                        dense
                        hide-default-footer
                        fixed-header
                        show-select
                        :items-per-page="999"
                        single-select
                        :height="dataTableHeight"
                        v-model="grupoSelected"
                        @input="selectedAfiliado()"
                      >
                        <template v-slot:[`header.data-table-select`]>
                          <span>Seleccionar</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-card>
          </v-form>
          <v-container class="py-0">
            <v-row class="py-0">
              <v-col cols="12" md="4" sm="12" class="pb-0">
                <v-autocomplete
                  ref="tiporelacion-autocomplete"
                  v-model="relacionSelected"
                  :items="tiposDeRelacion"
                  clearable
                  item-text="value"
                  item-value="id"
                  :rules="
                    relacionSelected == (null || '') ? [] : rules.required
                  "
                  outlined
                  dense
                  autocomplete="off"
                  label="Tipo de relación"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" sm="12" class="pb-0">
                <v-text-field
                  v-model="periodoDesde"
                  label="Período desde"
                  type="text"
                  outlined
                  hint="Formato AAAAMM"
                  :rules="
                    rules.required.concat(
                      rules.periodoYyyyMm,
                      rules.validDateRange(periodoDesde, periodoHasta)
                    )
                  "
                  dense
                  v-mask="'######'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12" class="pb-0">
                <v-text-field
                  v-model="periodoHasta"
                  label="Período hasta"
                  type="text"
                  hint="Formato AAAAMM"
                  outlined
                  :rules="
                    rules.required.concat(
                      rules.periodoYyyyMm,
                      rules.validDateRange(periodoDesde, periodoHasta)
                    )
                  "
                  dense
                  v-mask="'######'"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()">
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";

export default {
  name: "EditRelacionGrupoFamiliar",
  components: { FiltersSelected },
  props: {
    agectaId: { type: Number, required: true, default: null },
    agectaNom: { type: String, required: true, default: null },
    benAgectaId: { type: Number, required: false, default: null },
    benGrId: { required: true, default: null },
    periodoDesdeProp: { required: true, default: null },
    periodoHastaProp: { required: true, default: null },
    tipoRelacionId: { required: true, default: null },
    titularDni: { required: false, default: null },
    titularNom: { required: false, default: null }
  },
  directives: { mask },
  data() {
    return {
      title: "Nueva relación del agente con grupo familiar",
      rules: rules,
      isFormValid: false,
      filtersApplyed: [],
      gruposLoading: false,
      periodoDesde: null,
      periodoHasta: null,
      relacionSelected: null,
      tiposDeRelacion: [],
      grupoSelected: [],
      grupoFamiliarARelacionar: null,
      gruposFamiliaresResult: [],
      grupoTieneTitular: false,
      dataTableHeight: null,
      searchValid: false,
      numeroGrupoFamiliar: null,
      afiliadoSearchNombre: null,
      afiliadoSearchDocumento: null,
      afiliadoSearchApellido: null,
      grupoAutocompleteInput: null,
      filterApplied: false,
      banderaGrupoSelected: false,
      allowOverflow: false,
      busquedaIcon: "mdi-menu-down",
      busquedaAfiliado: true,
      toggleBusquedaTooltip: "Ocultar filtros",
      loading: false,
      headers: [
        {
          text: "Afiliado",
          align: "start",
          sortable: true,
          value: "value"
        },
        {
          text: "Documento",
          sortable: false,
          value: "doc"
        },
        {
          text: "Grupo Familiar",
          sortable: false,
          value: "nroGrupo"
        }
        // {
        //   text: "Seleccionar",
        //   value: "actions",
        //   sortable: false,
        //   align: "center"
        // }
      ]
    };
  },
  mounted() {
    this.setTiposDeRelacion();
    if (this.benAgectaId > 0) {
      this.banderaGrupoSelected = true;
      this.tieneTitularCheck();
      this.busquedaAfiliado = true;
      this.customizeFiltersApplied();
      this.setGrupoFamiliar();
    }
  },
  watch: {
    gruposFamiliaresResult(val) {
      if (val.length >= 4) {
        this.dataTableHeight = "10rem";
      } else if (val.length >= 2) {
        this.dataTableHeight = "7rem";
      } else {
        this.dataTableHeight = "5rem";
      }
    }
  },
  methods: {
    ...mapActions({
      getRelacionGrupoFamiliarById: "agenteCuenta/getRelacionGrupoFamiliarById",
      getTipoRelacion: "agenteCuenta/getTipoRelacion",
      fetchAfiliadosByQuery: "aportes/fetchAfiliadosByQuery",
      saveRelacionGrupoFamiliar: "agenteCuenta/saveRelacionGrupoFamiliar",
      fetchAfiliadosByParams: "agenteCuenta/fetchAfiliadosByParams",
      setAlert: "user/setAlert"
    }),
    async setTiposDeRelacion() {
      this.tiposDeRelacion = await this.getTipoRelacion();
    },
    tieneTitularCheck() {
      if (this.titularNom === null && this.titularDni === null) {
        this.banderaGrupoSelected = false;
        this.grupoTieneTitular = true;
        this.setAlert({
          type: "info",
          message: "El grupo familiar no tiene un titular activo"
        });
      }
    },
    setGrupoFamiliar() {
      this.title = "Editar relación del agente con grupo familiar";
      this.afiliadoSearchDocumento = this.titularDni;
      this.grupoTieneTitular ? [] : this.filterAfiliados();
      this.relacionSelected = this.tipoRelacionId;
      this.periodoDesde = this.periodoDesdeProp;
      this.periodoHasta = this.periodoHastaProp;
      this.numeroGrupoFamiliar = this.benGrId;
    },
    async saveEdit() {
      if (this.numeroGrupoFamiliar !== null) {
        const data = {
          benAgectaId: this.benAgectaId,
          benGrId: this.numeroGrupoFamiliar,
          agectaId: this.agectaId,
          tipoBenAgeId: this.relacionSelected,
          periodoDesde: this.periodoDesde.toString(),
          periodoHasta: this.periodoHasta.toString()
        };
        const response = await this.saveRelacionGrupoFamiliar(data);
        if (response.status === 200) {
          this.$emit;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.$emit("relacionCargada", true);
          this.closeModal();
        }
      } else {
        this.setAlert({
          type: "info",
          message: "Seleccione un afiliado, por favor"
        });
        this.busquedaAfiliado = true;
      }
    },
    toggleBusquedaAfiliado() {
      if (!this.busquedaAfiliado) {
        this.customizeFiltersApplied();
        this.toggleBusquedaTooltip = "Ocultar filtros";
        this.busquedaIcon = "mdi-menu-up";
      } else {
        this.customizeFiltersApplied();
        this.toggleBusquedaTooltip = "Mostrar filtros";
        this.busquedaIcon = "mdi-menu-down";
      }
      this.busquedaAfiliado = !this.busquedaAfiliado;
    },
    closeModal() {
      this.$emit("closeModalCargaRelacionGrupoFamiliar");
    },
    // metodos grupos familiares filter
    async filterAfiliados() {
      if (
        this.afiliadoSearchNombre != null ||
        this.afiliadoSearchDocumento != null ||
        this.afiliadoSearchApellido != null
      ) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          apellido: this.afiliadoSearchApellido,
          dni: parseInt(this.afiliadoSearchDocumento),
          nombre: this.afiliadoSearchNombre
        };
        try {
          this.filterApplied = true;
          const response = await this.fetchAfiliadosByParams(data);
          this.gruposFamiliaresResult = response;
          this.banderaGrupoSelected
            ? (this.grupoSelected = this.gruposFamiliaresResult)
            : [];
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];

      //  chequea si ya selecciono un afiliado en el data table
      if (this.grupoSelected.length > 0) {
        this.filtersApplyed.splice(0, 1, {
          key: "value",
          label: "Afiliado",
          model: this.grupoSelected[0].value.toUpperCase()
        });
        // aca chequea si todavia no selecciono afiliado pero escondio el modal de los filtros
      } else {
        if (this.afiliadoSearchDocumento) {
          this.filtersApplyed.splice(0, 1, {
            key: "dni",
            label: "N° de documento",
            model: this.afiliadoSearchDocumento
          });
        }
        if (this.afiliadoSearchNombre) {
          this.filtersApplyed.splice(1, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.afiliadoSearchNombre.toUpperCase()
          });
        }
        if (this.afiliadoSearchApellido) {
          this.filtersApplyed.splice(2, 1, {
            key: "apellido",
            label: "Apellido",
            model: this.afiliadoSearchApellido.toUpperCase()
          });
        }
      }
    },
    selectedAfiliado() {
      if (this.grupoSelected.length > 0) {
        this.numeroGrupoFamiliar = this.grupoSelected[0].nroGrupo;
        this.banderaGrupoSelected = false;
        this.toggleBusquedaAfiliado();
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
